<script lang="ts">
    import KubaparisSshToggle from './KubaparisSshToggle.svelte';
    import { routes } from '$lib/routes';
    import { page } from '$app/stores';
    import type { GlobalContentQuery, MenuFragmentFragment } from '$gen/graphql';
    import Search from './Search.svelte';
    import { createEventDispatcher } from 'svelte';
    import { navigating } from '$app/stores';
	const dispatch = createEventDispatcher();

    export let content: MenuFragmentFragment;
    export let postCategories: GlobalContentQuery['postCategories'];
    export let submissionPages: SubmissionPageType[];
    export let loading: Boolean;
    let animate = !$navigating;
    /** These handle routes where the menu looks differently. */
    $: onPostPage = $page.url.pathname.startsWith(routes.post_root);
    $: onSubmissionPostPage = $page.url.pathname.startsWith(routes.submission('', ''));
    $: onSubmitSuccessPage = $page.url.pathname === routes.submit_success;
    $: onAdvertisePage = $page.url.pathname === routes.advertise;

    $: whiteText = onSubmitSuccessPage;
    $: fixed = onSubmitSuccessPage || onPostPage || onSubmissionPostPage || onAdvertisePage;
    $: transparent = onSubmitSuccessPage || onPostPage || onSubmissionPostPage || onAdvertisePage;

    function buttonsappeared() {
        console.log('buttons appeared on menu')
        animate = false;
        dispatch('buttons_appeared')
    }
</script>

<nav
    class="w-full bg-white pt-5 dark:bg-black top-side-navigation"
    class:white-text={whiteText}
    class:fixed
    class:transparent
>
    {#if content}
        <ul id="main-menu" class="flex max-w-full items-baseline space-x-1">
            <KubaparisSshToggle 
                on:buttons_appeared={buttonsappeared}
            />
            <!-- Subpage links (internal) -->
            {#each content.menuSubpages as subpage (subpage.id)}
                {@const subpagePath = `/${subpage?.subpage[0]?.uri}`}
                <li class="text-shadow-white aaa dark:text-shadow-black hover:text-blue transition-slidein slow" class:hiddenonload={loading && animate}>
                    <a
                        class:italic={$page.url.pathname === subpagePath}
                        class=""
                        href={subpagePath}
                        sveltekit:prefetch>{subpage.label}</a
                    >
                </li>
            {/each}
            <!-- <li class="text-shadow-white dark:text-shadow-black hover:text-blue transition-slidein slow" class:hiddenonload={loading && animate}>
                <a href="https://kubaparis.bigcartel.com/" target="_blank" rel="external">Shop</a>
            </li> -->

            <!-- Seach input -->
            <div class="transition-slidein medium search-wrapper" class:hiddenonload={loading && animate}><Search /></div>
            <!-- Sozial media links (external) -->
            {#each content.socialMediaLinks as { link, label } (link + label)}
                {#if ! ( label === "Shop" ) }
                    <li class="text-shadow-white dark:text-shadow-black hover:text-blue transition-slidein medium" class:hiddenonload={loading && animate}>
                        <a href={link} target="_blank" rel="external">{label}</a>
                    </li>
                {/if}
            {/each}
        </ul>

        <div class="filter-advertisement-wrapper-navigation transition-slidein superslow" class:hiddenonload={loading && animate}>

            <!-- Post filter menu -->
            {#if $page.url.pathname === '/' || $page.url.pathname.startsWith('/category')}
                <!---<div id="events" class="ml-5 w-28 lg:w-32">
                    <div
                        class="kubaparis-shadow dark:kubaparis-shadow-dark relative h-[2rem] w-full rounded-full bg-white py-1.5 text-center text-black transition-shadow dark:bg-black dark:text-white"
                    >
                        <a class="" sveltekit:prefetch href={routes.calendar}>Calendar</a>
                    </div>
                </div>-->

                <ul
                    id="sub-menu"
                    class={`mt-5 flex col-start-2 justify-between ${
                        $page.url.pathname.startsWith('/category') ? 'col-span-2' : 'col-span-1'
                    }`}
                >
                    {#each postCategories as { uri, title, id } (id)}
                        {@const path = `/${uri}`}
                        <li class="hover:text-blue">
                            <a
                                href={path}
                                class:italic={$page.url.pathname === path}
                                sveltekit:prefetch
                            >
                                {title}
                            </a>
                        </li>
                    {/each}
                </ul>
            {/if}

            {#if $page.url.pathname === '/'}
                <div
                    class="grid h-full w-full place-content-end justify-center pb-0.5 pl-5 pr-5 text-center text-xs advertisement-wrapper-navigation"
                >
                    Advertisement
                </div>
            {/if}
        </div>
        <!-- Submission subpage menu -->
        <!--{#if $page.url.pathname.startsWith(routes.submit)}
            <ul id="sub-menu" class="col-start-2 mt-5 flex space-x-5">
                {#each submissionPages as { uri, title, id } (id)}
                    {@const path = `/${uri}`}
                    <li class="hover:text-blue">
                        <a href={path} class:italic={$page.url.pathname === path}>
                            {title}
                        </a>
                    </li>
                {/each}
            </ul>
        {/if} -->
    {/if}
</nav>

<style>
    .search-wrapper {
        flex-grow: 1;
    }
    .empty-space {
        width: 8rem;
    }
    #main-menu {
        grid-column: main;
    }

    /* #events {
        grid-column: side;
    } */

    #main-menu > li > a::after {
        content: ',';
    }

    li:last-child > a::after {
        content: none !important;
    }

    .white-text {
        color: theme('colors.white');
    }
    .fixed {
        position: fixed !important;
        top: 0;
        left: 0;
    }
    .transparent {
        background: theme('colors.transparent');
    }
    .transition-slidein {
        transform: translateX(0);
        opacity: 1;
        transition: opacity 1s ease;
    }
    .slow {
        transition: transform 2s ease;
    }
    .medium {
        transition: transform 1s ease;
    }
    .superslow {
        transition: transform 3s ease;
    }
    .fast {
        transition: transform .3s ease;
    }
    .hiddenonload {
        transform: translateX(100vw) !important;
        opacity: 0 !important;
    }
</style>
