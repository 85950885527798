import type {
    ArchivedPostFragment,
    PostTeaserFragment,
    SubmissionTeaserFragment,
} from '$gen/graphql';

export function mergePostAndSubmissionsByDate(
    posts: PostTeaserFragment[],
    submissions: SubmissionTeaserFragment[]
): (PostTeaserFragment | SubmissionTeaserFragment)[] {
    const mixed: (PostTeaserFragment | SubmissionTeaserFragment)[] = [].concat(posts, submissions);

    mixed
        .sort((a, b) => {
            if (a.timestamp < b.timestamp) {
                return -1;
            }
            if (a.timestamp > b.timestamp) {
                return 1;
            }
            return 0;
        })
        .reverse();

    return mixed;
}

export function mergeDifferentPostsBySearchScore(
    posts: PostTeaserFragment[],
    submissions: SubmissionTeaserFragment[],
    archivedPosts: ArchivedPostFragment[]
): (PostTeaserFragment | SubmissionTeaserFragment | ArchivedPostFragment)[] {
    const mixed: (PostTeaserFragment | SubmissionTeaserFragment | ArchivedPostFragment)[] 
    =
        [].concat(posts, submissions, archivedPosts);

    mixed.sort((a, b) => {
        if (parseInt(a.searchScore) < parseInt(b.searchScore)) {
            return 1;
        }
        if (parseInt(a.searchScore) > parseInt(b.searchScore)) {
            return -1;
        }
        return 0;
    });

    return mixed;
}


export function SubmissionsMerge(
    // posts: PostTeaserFragment[],
    submissions: SubmissionTeaserFragment[],
    // archivedPosts: ArchivedPostFragment[]
): (PostTeaserFragment | SubmissionTeaserFragment)[] {
    const mixed: (PostTeaserFragment | SubmissionTeaserFragment)[] = [].concat(submissions);

    mixed
        .sort((a, b) => {
            if (a.timestamp < b.timestamp) {
                return -1;
            }
            if (a.timestamp > b.timestamp) {
                return 1;
            }
            return 0;
        })
        .reverse();

    return mixed;
}