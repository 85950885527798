<script lang="ts">
    import type { SmallAdFragment } from '$gen/graphql';
    import { routes } from '$lib/routes';

    export let ad: SmallAdFragment;
    
</script>

{#if ad}
    <article>
        {#each ad.advertisingImage as img (ad.id)}
            {#if img.__typename === 'assets_Asset'}
                <a
                    target="_blank"
                    rel="external"
                    href={ad.externalLink}
                    on:click={async () => fetch(routes.api_countClick(ad.id))}
                >
                    {#if img.kind === 'image'}
                        <img
                            class="h-auto w-full"
                            src={img.url}
                            alt={img.title}
                            width={img.width}
                            height={img.height}
                            loading="lazy"
                        />
                    {:else if img.kind === 'video'}
                        <video
                            class="h-auto w-full"
                            src={img.url}
                            alt={img.title}
                            width={img.width}
                            height={img.height}
                            loading="lazy"
                            autoplay
                            loop
                            muted
                        />
                    {/if}
                </a>
            {/if}
        {/each}
    </article>
{/if}
